import VueRouter from 'vue-router'
import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/utils/vuetify'
import client from '@/utils/ajax/http'
import { getImgSrc, mixpanel, objToFormData } from '@/utils/helperFunctions'
import { loadStripe } from '@stripe/stripe-js'
import router from '@/utils/router'
import { initAxiosInterceptors } from '@/utils/ajax/authData'
import { createPinia, PiniaVuePlugin } from 'pinia'
import BackButton from '@/components/shared/BackButton.vue'
import PrimaryButton from '@/components/shared/PrimaryButton.vue'
import TextField from '@/components/shared/TextField.vue'
import UnderlineButton from '@/components/shared/UnderlineButton.vue'
import VSelect from '@/components/shared/VSelect.vue'
import * as Sentry from '@sentry/vue'
import { useAccountStore } from '@/stores/accountStore'

if (import.meta.env.VITE_NODE_ENV === 'staging') Vue.config.devtools = true

Vue.use(PiniaVuePlugin)
export const pinia = createPinia()

if (import.meta.env.VITE_NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://887ab96752044f9c8cc3426784146009@o121000.ingest.sentry.io/4504101741723648',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
          routeLabel: 'path',
        }),
        tracePropagationTargets: [
          /^http:\/\/localhost/,
          /^https:\/\/api0\.codewizardshq\.com/,
          /^https:\/\/api0\.cwhqstage\.com/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['api0.codewizardshq.com', 'api0.cwhqstage.com'],
        networkResponseHeaders: ['X-Git-Version'],
      }),
    ],
    environment: import.meta.env.VITE_NODE_ENV,
    tracesSampleRate: 0.01,
    sampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
    release: import.meta.env.VITE_GIT_VERSION,
    // tunnel: import.meta.env.VITE_API_BASE_URL + '/learn/api/user/tunnel',
  })
}

Vue.component('BackButton', BackButton)
Vue.component('TextField', TextField)
Vue.component('SelectBox', VSelect)
Vue.component('PrimaryButton', PrimaryButton)
Vue.component('UnderlineButton', UnderlineButton)
Vue.use(VueRouter)

export const app = new Vue({ router, vuetify, pinia, render: (h) => h(App) })

const accountStore = useAccountStore()
initAxiosInterceptors(client, accountStore)

Vue.prototype.$alert = alert
Vue.prototype.$http = client
Vue.prototype.$objToFormData = objToFormData // TODO: get rid of form data
Vue.prototype.$mixpanel = mixpanel
Vue.prototype.$img = getImgSrc

export function alert(
  message: string,
  color = 'success',
  multiline = true,
  timeout = null
) {
  accountStore.setAlert({ message, color, multiline, timeout })
}

export function error(message: string, multiline = true, timeout = 5000) {
  accountStore.setAlert({ message, color: 'error', multiline, timeout })
}

async function initVue() {
  const key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
  try {
    Vue.prototype.$stripe = await loadStripe(key)
    app.$mount('#app')
  } catch (e) {
    console.error(e)
  }
}

initVue()
